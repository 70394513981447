<template>
  <PSignupForm @submit="handleSubmit">
    <template slot="title">
      {{ $t('検証コード入力') }}
    </template>
    <template slot="contents">
      <p
        class="p-authSection__attention"
        v-t="'ユーザー登録はまだ完了していません。'"
      ></p>
      <p
        class="p-authSection__lead content"
        v-t="{
          path:
            '{username}に検証コードを送信しました。\n送信された検証コードを入力してください。\nこの後、セキュリティコードを入力していただく必要があります。ご案内されているセキュリティコードを準備してください。',
          args: { username },
        }"
      />
      <div class="p-authInputs">
        <PAuthInput
          class="p-authInputs__input"
          v-model.trim="$v.code.$model"
          :placeholder="$t('検証コード')"
          autocomplete="off"
          required
          :is-error="codeError"
        />
        <p
          v-if="errorCode"
          class="p-authInputs__errorText"
          v-t="getErrorMessage(errorCode)"
        />
      </div>
      <button
        type="submit"
        class="p-authSection__button c-button"
        v-t="'次へ進む'"
        :disabled="$v.$invalid"
      />
      <div class="p-authSection__link">
        <button
          type="button"
          class="c-link"
          v-t="'検証コードを再送する'"
          @click="handleClickResendLink"
        />
      </div>
    </template>
  </PSignupForm>
</template>

<script>
import PAuthInput from '@/components/auth/PAuthInput.vue'
import PSignupForm from '@/components/auth/PSignupForm.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AccountVerificationsPresentation',
  components: {
    PAuthInput,
    PSignupForm,
  },
  props: {
    errorCode: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      code: '',
    }
  },
  validations: {
    code: {
      required,
    },
  },
  computed: {
    codeError() {
      return this.$v.code.$error || this.errorCode === 'CodeMismatchException'
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('confirmSignup', {
        code: this.code,
      })
    },
    handleClickResendLink() {
      this.$emit('resendSignup')
    },
    getErrorMessage(code) {
      switch (code) {
        case 'CodeMismatchException':
          return '検証コードを確認してください。'
        case 'LimitExceededException':
          return '試行制限に達しました。時間をあけて再度お試しください。'
      }
    },
  },
}
</script>

<template>
  <AccountVerificationsPresentation
    @confirmSignup="handleConfirmSignup"
    @resendSignup="handleResendSignup"
    :username="maskedUsername"
    :error-code="errorCode"
  />
</template>

<script>
import AccountVerificationsPresentation from '@/components/auth/AccountVerificationsPresentation.vue'
import store from '@/store'
import {
  cognitoConfirmSignUp,
  cognitoResendSignUp,
} from '@/assets/modules/cognito'

export default {
  name: 'AccountVerifications',
  components: { AccountVerificationsPresentation },
  metaInfo() {
    return {
      title: this.$t('検証コード入力'),
    }
  },
  data() {
    return {
      errorCode: '',
    }
  },
  computed: {
    username() {
      return this.$route.query.username
    },
    maskedUsername() {
      return this.username.replace(/(.).*?@.*\.(.*)/, '$1***@***.$2')
    },
  },
  beforeRouteEnter(to, _from, next) {
    // ログイン済みの場合トップにリダイレクト
    if (store.getters['auth/isLoggedIn']) {
      next({
        name: 'Index',
        params: {
          portalId: to.params.portalId,
        },
      })
      return
    }

    // クエリにメールアドレスが付与されていない場合、メールアドレスの形式ではない場合はログイン画面にリダイレクト
    if (
      !to.query.username ||
      !/^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(
        to.query.username
      )
    ) {
      next({
        name: 'Login',
        params: {
          portalId: to.params.portalId,
        },
      })
      return
    }

    next()
  },
  methods: {
    async handleConfirmSignup({ code }) {
      const result = await cognitoConfirmSignUp(this.username, code)

      if (result.isError && result.data.code !== 'NotAuthorizedException') {
        this.errorCode = result.data.code
        return
      }

      this.$router.push({
        name: 'Login',
        query: { redirect: this.$route.query.redirect },
      })
    },
    async handleResendSignup() {
      const result = await cognitoResendSignUp(this.username)

      if (result.isError) {
        this.errorCode = result.data.code
        return
      }

      this.errorCode = ''
    },
  },
}
</script>
